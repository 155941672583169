.orders-page {
  background: $background;

  .orders{
    &-header {
      display: flex;
      align-items: center;
      width: 100%;
      color: #646464;
      font-weight: 500;
      position: relative;
      justify-content: space-between;

      &-wrapper {
        padding: 15px 5px;
      }

      &-left{
        padding-left: 0.285em;
      }
    }

    &-scroll {
      max-height: 100vh;
      &-item{
        padding: 0;
      }
    }

    &-card {
      color: $dark;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      @media (max-width: $breakpoint-sm-max) {
        flex-wrap: wrap;
      }


      &-wrapper {
        cursor: pointer;
        border-radius: 8px;
        padding: 10px 5px;
        margin-bottom: 10px;
        min-height: 60px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        &:not(.q-skeleton) {
          background: #fff;
          transition: box-shadow .3s ease-in-out;
          &:hover{
            box-shadow: 0 0 1.25rem #0000001a;
          }
        }
      }

      &-offers{
        &-title{
          width: 20%;
        }
        &-type{
          width: 15%;
        }
        &-date{
          width: 15%;
        }
        &-qty{
          width: 10%;
        }
        &-total{
          width: 15%;
        }
        &-status{
          width: 15%;
        }
        &-actions{
          width: 10%;
          display: flex;
          gap: 10px;
          justify-content: flex-end;
          margin-left: auto;
        }
      }

      &-left{
        width: 3%;
      }
      &-source {
        width: 5%;
      }
      &-type {
        width: 5%;
      }
      &-number{
        width: 8%;
      }
      &-customer{
        width: 15%;
      }
      &-date{
        width: 15%;
      }
      &-shipped-date{
        width: 15%;
      }
      &-total{
        width: 10%;
      }
      &-status{
        width: 10%;
      }
      &-actions{
        width: 15%;
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        margin-left: auto;
      }

      &-checkbox{
        margin-right: 5px;
        .q-checkbox__inner--falsy{
          .q-checkbox__bg{
            background: #fff;
          }
        }
      }

      > div{
        padding-left: 0.285em;
      }
    }

    &-pagination{
      gap: 5px;
      .row{
        gap: 5px;
      }
      .q-btn{
        background: #fff;
        padding: 5px!important;
      }
    }
  }

  .single-order {
    &-card {
      border-radius: 8px;
      padding: 10px 12px;
      background: #fff;
      height: 100%;

      &-label {
        font-weight: 600;
        font-size: 12px;
      }
    }
  }
}

.order-shipping-row{
  &.move-top{
    margin-top: -20px;
  }
  .q-btn-dropdown{
    .q-btn__content{
      justify-content: space-between;
    }
  }
}

.order-items-table{
  &-row{
    background: #fff;
    border-radius: 8px;
    padding: 6px 12px;
    margin-bottom: 10px;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    &.accepted{
      background: #ddecdf;
    }
    &.declined{
      background: #FFCDD2;
    }
    //&:last-child{
    //  margin-bottom: 0;
    //}

    &-left{
      width: 10%;
    }

    &-product{
      width: 20%;

      &-image{
        margin-right: 8px;
      }
      &-name{
        font-weight: 600;
        //font-size: 16px;
      }
    }

    &-brand{
      width: 10%;
      font-weight: 500;
    }

    &-qty{
      width: 10%;
      font-weight: 500;
    }

    &-qoh{
      width: 10%;
      font-weight: 500;
    }

    &-unit-price, &-total-price{
      width: 10%;
      font-weight: 500;
    }

    &-notes{
      width: 20%;
      &.small{
        width: 10%;
      }
    }

    &-actions{
      width: 10%;
      text-align: right;
    }

    &-divider{
      width: 100%;
      height: 2px;
      background: $grey-3;
      margin-top: 10px;
    }

    &-packages{
      padding-top: 16px;
      width: 100%;
      display: flex;

      .packages-main{
        //width: 50%;
        width: 100%;
        max-width: 800px;
        table{
          width: 100%;
          th {
            text-align: left;
          }
        }
      }
      .new-package{
        width: 50%;
        &-form{
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 10px;
          grid-auto-rows: minmax(100px, auto);
        }
      }
    }
  }

  &-header{
    padding: 5px 12px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    position: relative;
    &.left-padding{
      padding-left: 57px;
    }

    .bulk-actions{
      position: absolute;
      left: 0;

      .q-checkbox__label{
        margin-left: 20px;
      }
    }
  }

  &-accepted{
    background: #fff;
    border-radius: 8px;
  }

  &-checkbox{
    width: 45px;
    padding-top: 20px;
  }
}

.my-orders{
  &-header{
    &-select {
      min-width: 100px;
    }
    &-dropdown {
      min-width: 150px;
      font-size: 14px;
      font-weight: normal;
      text-transform: capitalize;

      &.per-page {
        min-width: 100px;
      }
    }
  }
}

