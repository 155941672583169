.cart-form {
  &-item {
    display: flex;

    &-image {
      margin-right: 16px;

      &-holder {
        width: 96px;
        height: 96px;

        img {
          border-radius: 16px;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        .q-skeleton {
          border-radius: 16px;
          width: 100%;
          height: 100%;
          background: $grey;
        }
      }
    }

    &-info {
      margin-right: 13px;
    }

    &-lbs {
      font-size: 14px;
      line-height: 100%;
      font-weight: 700;
      color: $primary;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    &-title {
      font-size: 18px;
      line-height: 100%;
      font-weight: 700;
      color: $primary;
      margin-bottom: 10px;
      margin-top: 0;
    }

    &-lot {
      color: $grey-9;
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      margin-bottom: 17px;
      &-number {
        margin-left: 5px;
        font-weight: 700;
        color: $primary;
      }
    }

    &-win-line {
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
    }

    &-side {
      margin-left: auto;
      align-self: flex-end;
    }
  }
  &-payment-select {
    margin-bottom: 24px;
    &-current {
    }
  }
  &-card {
    display: flex;
    align-items: center;
    .card {
      &-brand {
        margin-right: 16px;
        &-holder {
          background: $grey;
          border-radius: 8px;
          width: 40px;
          height: 40px;
          position: relative;
        }
        &-name {
          color: $primary;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 10px;
          font-weight: 700;
        }
      }
      &-info {
        color: $grey-9;
        font-size: 16px;
        line-height: 130%;
        margin-right: 16px;
        &-title {
          font-weight: 700;
        }
        &-number {
          font-weight: 400;
        }
      }
      &-side {
        margin-left: auto;
      }
    }
  }
  &-summary-table {
    width: 100%;
    font-size: 14px;
    line-height: 26px;
    color: $primary;
    .price-value {
      color: inherit;
      font-size: 16px;
    }
    &-due-today-row {
      color: $primary;
      line-height: 32px;
      .price-value {
        font-size: 18px;
      }
    }
    th {
      text-align: left;
    }
    td {
      text-align: right;
    }
  }
  &-submit {
    &-btn {
      width: 100%;
      max-width: 400px;
      margin-bottom: 24px;
    }
  }
}
