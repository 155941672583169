.selected-packages-list {
  width: 100%;
}

.selected-package-card {
  color: $dark;
  font-size: 14px;
  display: flex;
  align-items: center;
  width: 90%;
  @media (max-width: $breakpoint-sm-max) {
    flex-wrap: wrap;
  }

  .q-field--labeled .q-field__native,
  .q-field--labeled .q-field__prefix,
  .q-field--labeled .q-field__suffix {
    padding-top: 14px;
    padding-bottom: 2px;
  }

  &-wrapper {
    border-radius: 8px;
    padding: 2px 0;
    margin-right: 20px;
    flex-wrap: wrap;
  }

  &-selection {
    width: 5%;
    margin-right: 10px;

    @media (max-width: $breakpoint-sm-max) {
    }
  }

  &-main-tag {
    width: 30%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    @media (max-width: $breakpoint-sm-max) {
    }
  }

  &-title {
    font-size: 16px;
    font-weight: 600;
    width: 30%;

    @media (max-width: $breakpoint-sm-max) {
      width: 20%;
    }

    @media (max-width: $breakpoint-xs-max) {
      width: 33.3%;
    }
  }

  &-quantity {
    width: 15%;

    @media (max-width: $breakpoint-sm-max) {
      width: 20%;
    }

    @media (max-width: $breakpoint-xs-max) {
      width: 66.6%;
    }
  }

  &-strain {
    width: 30%;

    @media (max-width: $breakpoint-sm-max) {
      width: 20%;
    }

    @media (max-width: $breakpoint-xs-max) {
      width: 33.3%;
    }
  }
  &-harvest-date {
    width: 20%;

    @media (max-width: $breakpoint-sm-max) {
      width: 20%;
    }

    @media (max-width: $breakpoint-xs-max) {
      width: 33.3%;
    }
  }

  &-dry-location {
    width: 15%;

    @media (max-width: $breakpoint-sm-max) {
      width: 20%;
    }

    @media (max-width: $breakpoint-xs-max) {
      width: 66.6%;
    }
  }
}

.view-toggle-btn {
  font-size: 0.875rem;
  color: var(--q-primary);
}

.view-toggle-btn .q-icon {
  transition: transform 0.2s ease-in-out;
}

.view-toggle-btn .q-icon.rotate {
  transform: rotate(180deg);
}
