.transfers-page {
  background: $background;

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
  }

  .info {
    font-size: 1.3rem;
  }
}

.my-transfers {
  .top-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    .filters-container {
      display: flex;
      gap: 10px;
      justify-content: flex-start;

      .q-field {
        min-width: 200px;
      }
    }

    .actions-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 0;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    width: 100%;
    color: #646464;
    font-weight: 500;
    padding: 15px 12px;

    &-wrapper {
      position: sticky;
      top: 0;
      z-index: 2;
    }

    &-shipment {
      padding-left: 42px;
      width: 22%;
    }

    &-manifest {
      width: 20%;
    }

    &-receiver {
      width: 12%;
    }

    &-shipped-value {
      width: 15%;
    }

    &-pick-up {
      width: 15%;
    }

    &-status {
      flex: 1;
      display: flex;
      gap: 6px;

      .q-btn {
        &.edit-btn {
          background: #2196f3;
          color: white;
        }

        &.confirm-btn {
          background: #4caf50;
          color: white;
        }
      }
    }

    .sortable-header {
      display: inline-flex;
      align-items: center;

      &.active {
        color: $primary;
      }

      .q-icon {
        margin-left: 5px;
        font-size: 16px;
      }
    }
  }

  &-card {
    &-wrapper {
      width: 100%;
      border-radius: 8px;
      background: white;
      margin-bottom: 10px;
      text-decoration: none;
      color: inherit;

      &:hover {
        background: rgba(0, 0, 0, 0.02);
      }
    }
  }
}

.my-transfers-card {
  display: flex;
  align-items: center;
  padding: 16px;
  width: 100%;

  &-shipment {
    width: 22%;
    padding-right: 12px;
    display: flex;
    align-items: center;

    .transfer-id {
      font-weight: 500;
      margin-left: 4px;
    }

    .checkbox-container {
      width: 24px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-title {
    font-weight: 500;
    font-size: 1.1em;
    margin-bottom: 4px;
  }

  &-lot {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.9em;
    color: #666;

    .q-checkbox {
      margin-left: -8px;
    }
  }

  &-manifest {
    width: 20%;
    padding: 0 12px;
    display: flex;
    align-items: center;

    .manifest-view-btn {
      flex-shrink: 0;
    }
  }

  &-receiver {
    width: 15%;
  }

  &-shipped-value {
    width: 15%;
  }

  &-pick-up {
    width: 15%;
  }

  &-status {
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;

    .status-text {
      font-size: 0.9em;
      font-weight: 500;
      display: flex;
      align-items: center;
    }

    .buttons-container {
      display: flex;
      gap: 4px;
      justify-content: center;

      .q-btn {
        min-width: 60px;
        font-size: 0.7rem;
      }
    }
  }
}

@media (max-width: $breakpoint-sm-max) {
  .my-transfers-card {
    &-shipment {
      width: 100%;
    }

    &-manifest,
    &-receiver {
      width: 50%;
      padding: 0;
    }

    &-shipped-value,
    &-pick-up,
    &-status {
      width: 33.33%;
      padding: 0;
    }
  }
}

@media (max-width: $breakpoint-sm-max) {
  .my-transfers-card {
    flex-wrap: wrap;
    gap: 12px;

    &-shipment {
      width: 100%;
      order: 1;
    }

    &-manifest,
    &-receiver {
      width: 50%;
      padding: 0;
      order: 2;
    }

    &-shipped-value,
    &-pick-up,
    &-status {
      width: 33.33%;
      text-align: center;
      padding: 0;
      order: 3;
    }

    &-actions {
      width: 100%;
      order: 4;
      justify-content: center;
    }
  }
}

@media (max-width: $breakpoint-xs-max) {
  .my-transfers-card {
    &-manifest,
    &-receiver,
    &-shipped-value,
    &-pick-up,
    &-status {
      width: 100%;
      text-align: left;
      margin-bottom: 8px;
    }
  }
}
