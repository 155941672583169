.listing-page {
  &-icon-button {
    .q-icon {
      align-self: baseline;
    }
  }
  &-bid-buttons {
    font-size: 18px;
    padding-top: 44px;
  }
  &-order-buttons {
    font-size: 18px;
    padding-top: 12px;
  }
  &-about-table {
    width: 90%;
    text-align: left;
    border-collapse: collapse;
    margin: 24px 5% 20px;
    table-layout: fixed;

    th {
      font-weight: 400;
      color: $grey-9;
      font-size: 14px;
      line-height: 100%;
      padding: 16px 16px 6px 16px;
      // border: 1px solid #C4C4C4;
      border-bottom: none;
      @media screen and (max-width: $breakpoint-xs-max) {
        font-size: 13px;
      }
    }
    td {
      font-weight: 700;
      color: $primary;
      font-size: 16px;
      line-height: 100%;
      padding: 6px 16px 16px 16px;
      // border: 1px solid #C4C4C4;
      border-top: none;
      width: 0.1%;
      @media screen and (max-width: $breakpoint-xs-max) {
        font-size: 16px;
      }
    }
    tr {
      td,
      th {
        &:first-child {
          border-left: none;
        }
        &:last-child {
          border-right: none;
        }
      }
      &:first-child {
        th {
          border-top: none;
        }
      }
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
  .listing-brand {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    img {
      width: 25px;
      height: auto;
      margin-right: 5px;
    }
  }
  &-gallery {
    height: 42vh;
    min-height: 210px;
    margin-bottom: 12px;
    min-height: 400px;
    // background-color: $grey-1;

    &-contents {
      height: 43vh;
      min-height: 210px;
      width: 100%;
      font-size: 2vh;
    }
    &-slide {
      width: 41vh;
      height: 41vh;
      flex-shrink: 0;

      &-image {
        border-radius: 16px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  &-description {
    font-size: 16px;
    line-height: 21px;
    color: $grey-9;
  }
  &-time {
    background: #f7f8f8;
    padding: 20px 0;
    margin-bottom: 33px;
    &-ends {
      font-size: 14px;
      line-height: 140%;
      color: $grey-9;
    }
    &-left {
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
      color: $primary;
      margin-bottom: 4px;
      margin-top: 8px;
    }
  }
  &-lot {
    color: $grey-9;
    font-size: 14px;
    line-height: 100%;
    &-number {
      color: $primary;
      font-weight: 700;
      display: inline;
      margin-left: 5px;
    }
  }
  &-coa {
    font-size: 14px;
    line-height: 100%;
    font-weight: 700;
    color: $grey-9;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
    span {
      margin-top: 2px;
    }
  }

  .right-column {
    .q-carousel {
      .q-carousel__navigation--bottom {
        bottom: 26px;
      }
      .q-carousel__slide {
        padding: 6px;
      }
      .q-carousel__control button {
        min-width: 10px !important;
        min-height: 10px !important;

        .q-icon {
          font-size: 10px !important;
        }
      }

      .q-carousel__prev-arrow button,
      .q-carousel__next-arrow button {
        min-width: 24px !important;
        min-height: 24px !important;
        margin-right: -4px;
        margin-left: -4px;
      }

      .q-carousel__prev-arrow button .q-icon,
      .q-carousel__next-arrow button .q-icon {
        font-size: 18px !important;
      }
    }
  }

  .interaction-row {
    .q-field__marginal {
      font-size: 14px;
    }
  }
}

.boxes-list {
  &__item {
    display: flex;
    align-items: center;
    min-height: 0;
  }
  &__input {
    margin-right: 2rem;
    .q-field__control {
      height: auto;
    }
    .q-field__native {
      width: 2rem;
    }
    input {
      text-align: center;
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}

.listings-page {
  background: $background;
}
.my-listings {
  &-header {
    display: flex;
    align-items: center;
    width: 100%;
    color: #646464;
    font-weight: 500;
    position: relative;

    &-wrapper {
      padding: 15px 12px;
    }

    .sortable-header {
      .q-icon {
        transition: opacity 0.3s ease-in-out;
        margin-left: 5px;
        font-size: 16px;
      }
      &:not(.active) {
        .q-icon {
          opacity: 0.3;
        }
        &:hover {
          .q-icon {
            opacity: 0.8;
          }
        }
      }
    }

    .my-listings-card {
      &-main {
        padding-left: 76px;

        @media (max-width: $breakpoint-sm-max) {
          display: none;
        }
      }

      &-category-type {
        @media (max-width: $breakpoint-sm-max) {
          display: none;
        }
      }

      &-strain-type {
        @media (max-width: $breakpoint-sm-max) {
          display: none;
        }
      }

      &-reserve {
        @media (max-width: $breakpoint-sm-max) {
          display: none;
        }
      }

      &-current-bid {
        @media (max-width: $breakpoint-sm-max) {
          display: none;
        }
      }

      &-bids {
        @media (max-width: $breakpoint-sm-max) {
          display: none;
        }
      }

      &-filters {
        width: 40%;
        text-align: right;

        @media (max-width: $breakpoint-sm-max) {
          width: 100%;
        }

        @media (min-width: $breakpoint-md-min) {
          width: auto;
          position: absolute;
          right: 0;
          bottom: 0;
        }

        > * {
          margin-right: -12px;
          margin-left: 22px;
        }
      }
    }

    &-select {
      background: #fff;
      display: inline-flex;
      border-radius: 6px;
      overflow: hidden;
      min-width: 110px;

      .q-field {
        &__native {
          color: $dark;
          font-weight: 500;
        }
        &__label {
          color: #565656;
        }
      }

      .q-select {
        &__dropdown-icon {
          color: $dark;
          font-size: 14px;
        }
      }
    }
  }

  &-scroll {
    max-height: 100vh;
  }

  &-card {
    color: $dark;
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    @media (max-width: $breakpoint-sm-max) {
      flex-wrap: wrap;
    }

    &-list-wrapper {
      padding: 0;
    }

    &-wrapper {
      border-radius: 8px;
      padding: 10px 12px;
      margin-bottom: 10px;
      min-height: 80px;
      width: 100%;
      display: block;
      text-decoration: none !important;

      &:not(.q-skeleton) {
        background: #fff;
      }
    }

    &-main {
      width: 25%;
      display: inline-flex;
      align-items: center;

      @media (max-width: $breakpoint-sm-max) {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    &-category-type {
      width: 15%;

      @media (max-width: $breakpoint-sm-max) {
        width: 25%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 50%;
      }
    }

    &-strain-type {
      width: 15%;

      @media (max-width: $breakpoint-sm-max) {
        width: 25%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 50%;
      }
    }

    &-current-bid {
      width: 10%;

      @media (max-width: $breakpoint-sm-max) {
        width: 25%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 50%;
      }
    }

    &-bids {
      width: 10%;

      @media (max-width: $breakpoint-sm-max) {
        width: 25%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 50%;
      }
    }

    &-reserve {
      width: 10%;

      @media (max-width: $breakpoint-sm-max) {
        width: 50%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 50%;
      }
    }

    &-ends {
      width: 15%;
      //text-align: right;

      @media (max-width: $breakpoint-sm-max) {
        width: 50%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 50%;
      }
    }

    &-image {
      background: $dark;
      border-radius: 8px;
      margin-right: 16px;
      min-width: 60px;
      min-height: 60px;
    }

    &-clickable {
      color: $primary;
      font-weight: 500;

      &.danger {
        color: $negative;
      }
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
    }

    &-qty {
      font-size: 14px;
      font-weight: 600;
    }

    &-lot {
      font-size: 14px;
      span {
        font-weight: 600;
      }
    }

    &-cancelled {
      font-size: 15px;
      color: $negative;
      font-weight: 600;
    }

    &-left {
      color: $primary;
      font-weight: 500;
    }
  }
}
