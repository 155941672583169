.leaflink-import-page {
  background: $background;

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
  }

  .packages-loading-notice {
    position: fixed;
    bottom: 24px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: $secondary;
    padding: 6px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    width: max-content;
    @media screen and (max-width: $breakpoint-sm) {
      bottom: 84px;
    }
  }
}

.compact-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
}

.stats-container {
  font-size: 14px;
  color: $grey-8;
}

.leaflink-items {
  &-header {
    .leaflink-items-card-ll-product-image {
      background-color: transparent !important;
      background: none !important;
      width: 60px;
      height: 60px;
      margin-right: 16px;
    }
  }
  .top-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

  .filters-container {
    display: flex;
    gap: 10px;
  }

  &-header {
    display: flex;
    align-items: center;
    width: 100%;
    color: #646464;
    font-weight: 500;
    position: relative;

    .leaflink-items-card-ll-brand-image {
      padding: none;
    }

    &-wrapper {
      padding: 2px 12px;
    }

    .sortable-header {
      .q-icon {
        transition: opacity 0.3s ease-in-out;
        margin-left: 5px;
        font-size: 16px;
      }
    }

    &-select {
      background: #fff;
      display: inline-flex;
      border-radius: 6px;
      overflow: hidden;
      min-width: 180px;

      .q-field {
        &__native {
          color: $dark;
          font-weight: 500;
        }
        &__label {
          color: #565656;
        }
      }

      .q-select {
        &__dropdown-icon {
          color: $dark;
          font-size: 14px;
        }
      }
    }
  }

  &-scroll {
    max-height: 100vh;
  }

  &-card {
    color: $dark;
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    @media (max-width: $breakpoint-sm-max) {
      flex-wrap: wrap;
    }

    &-wrapper {
      border-radius: 8px;
      margin-bottom: 10px;
      min-height: 80px;
      flex-wrap: wrap;

      &:not(.q-skeleton) {
        background: #fff;
      }
    }

    &-main {
      width: 22%;
      display: flex;
      align-items: center;
    }

    &-ll-image {
      width: 12%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;

      .image-container {
        border-radius: 8px;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
        overflow: hidden;
      }

      &.no-image {
        background: $secondary;
        color: $primary;
        font-weight: 500;
        padding: 10px;
        width: 60px;
        height: 60px;
        text-align: center;
        position: relative;

        .no-image-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    &-ll-brand-image {
      width: 12%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;

      .brand-image-container {
        border-radius: 8px;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
        overflow: hidden;
      }

      .ll-brand-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .no-brand-image {
        background: $secondary;
        color: $primary;
        font-weight: 500;
        padding: 10px;
        width: 60px;
        height: 60px;
        text-align: center;
        position: relative;

        .no-image-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      &.no-image {
        color: $primary;
        font-weight: 500;
        padding: 10px;
        width: 60px;
        height: 60px;
        text-align: center;
        position: relative;

        .no-image-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .brand-name {
        font-size: 12px;
        color: #333;
        text-align: center;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-ll-product {
      width: 20%;
      padding-right: 12px;

      .q-select {
        width: 100%;

        .q-field__native {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    &-unit-price,
    &-wholesale-price,
    &-case-size {
      width: 10%;
      text-align: center;
      padding: 0 10px;

      @media (max-width: $breakpoint-sm-max) {
        width: 15%;
        flex: none;
        text-align: right;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 25%;
        text-align: center;
        padding: 5px 0;
      }
    }

    &-select {
      width: 4%;
      text-align: right;
      padding: 0 10px;
      @media (max-width: $breakpoint-sm-max) {
        width: 10%;
      }
      @media (max-width: $breakpoint-xs-max) {
        width: 25%;
        text-align: right;
        padding: 5px 0;
      }
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
    }

    &-clickable {
      color: $primary;
      font-weight: 500;

      .accordion-icon {
        color: #646464;
        background: #f7f7f7;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }

      .q-btn {
        &__content {
          gap: 5px;
          flex-wrap: nowrap;
        }
      }
    }

    &-button {
      border-radius: 6px;
      font-size: 14px;
      font-weight: 500;
    }

    @media (max-width: $breakpoint-sm-max) {
      flex-wrap: wrap;
      justify-content: flex-start;

      &-main {
        width: 25%;
      }
      &-ll-brand-image {
        width: 25%;
      }
      &-ll-product {
        width: 25%;
      }
      &-ll-image {
        width: 25%;
      }
    }

    @media (max-width: $breakpoint-xs-max) {
      &-main {
        margin-top: 12px;
        width: 100%;
        order: 1;
      }
      &-ll-brand-image {
        width: 33.33%;
        order: 2;
      }
      &-ll-product {
        width: 33.33%;
        order: 3;
      }
      &-ll-image {
        width: 33.33%;
        order: 3;
      }
      &-unit-price,
      &-wholesale-price,
      &-case-size {
        order: 4;
      }
      &-select {
        order: 5;
      }
    }
  }
}

.icon-color {
  color: #2a6b0e;
}

.mobileBreak {
  @media (max-width: 600px) {
    margin-top: 88px !important;
  }
}

.hide-no-packages-container {
  position: absolute;
  top: 15px;
  left: 230px;
  @media screen and (max-width: 500px) {
    left: 0;
    top: 42px;
  }
}
