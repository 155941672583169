.batch-transfer-page,
.batch-tag-create-page {
  background: $background;
}

.batch-items-card-main {
  display: flex;
  flex-direction: row;
  width: 30%;
  align-items: center;
}

.batch-items-card-main-title {
  display: flex;
  flex-direction: row;
  width: 22%;
  align-items: center;

  @media (max-width: $breakpoint-sm-max) {
    width: 20%;
  }
}

.batch-item-details {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.batch-items {
  &-header {
    display: flex;
    align-items: center;
    width: 100%;
    color: #646464;
    font-weight: 500;
    position: relative;

    &-wrapper {
      padding: 15px 0px;
    }

    .sortable-header {
      .q-icon {
        transition: opacity 0.3s ease-in-out;
        margin-left: 5px;
        font-size: 16px;
      }
    }

    .batch-items-card {
      &-main {
        width: 20%;

        @media (max-width: $breakpoint-sm-max) {
          width: 20%;
        }
      }
      &-selection {
        @media (max-width: $breakpoint-sm-max) {
          width: 8%;
        }
      }
      &-harvest-date {
        @media (max-width: $breakpoint-sm-max) {
          width: 25%;
        }
      }
      &-strain-type {
        @media (max-width: $breakpoint-sm-max) {
          width: 20%;
        }
      }
      &-drying-location {
        @media (max-width: $breakpoint-sm-max) {
          width: 20%;
        }
      }
      &-quantity {
        @media (max-width: $breakpoint-sm-max) {
          width: 20%;
        }
      }
    }
  }

  &-select {
    background: #fff;
    display: inline-flex;
    border-radius: 6px;
    overflow: hidden;
    min-width: 110px;

    .q-field {
      &__native {
        color: $dark;
        font-weight: 500;
      }
      &__label {
        color: #565656;
      }
    }

    .q-select {
      &__dropdown-icon {
        color: $dark;
        font-size: 14px;
      }
    }
  }

  &-scroll {
    max-height: 80vh;
  }

  &-card {
    color: $dark;
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    @media (max-width: $breakpoint-sm-max) {
      flex-wrap: wrap;
    }

    &-wrapper {
      border-radius: 8px;
      padding: 4px 12px;
      margin-bottom: 10px;
      flex-wrap: wrap;
      background: #fff;
    }

    &-selection {
      width: 8%;
      display: flex;
      align-items: center;

      @media (max-width: $breakpoint-sm-max) {
        width: 5%;
      }
    }

    &-main {
      display: flex;
      align-items: center;

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
        margin-bottom: 10px;
      }
    }

    &-harvest-date {
      width: 20%;

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }
    }

    &-strain-type {
      width: 20%;

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 66.6%;
      }
    }

    &-drying-location {
      width: 18%;

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }
    }

    &-quantity {
      width: 10%;

      @media (max-width: $breakpoint-sm-max) {
        width: 10%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 10%;
      }
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
    }

    &-filters {
      width: 13%;
      text-align: right;
    }
  }
}
