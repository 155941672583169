.auction-categories {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  padding: 18px;
  padding-top: 0;
  overflow-x: auto;

  &-card {
    border-radius: 0.75vh;
    text-align: center;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12);
    height: 130px;
    width: 100%;
    width: 130px;
    overflow: hidden;

    &-container {
      flex: 0 0 auto;
    }

    &-selected {
      border-radius: 0.75vh;
      text-align: center;
      border: 1px solid rgba(211, 211, 211, 0.395);
      height: 34vh;
      height: 130px;
      width: 130px;
      overflow: hidden;
      box-shadow: 0 2px 5px rgba(90, 90, 90, 0.4),
        0 2px 2px rgba(90, 90, 90, 0.14), 0 3px 1px -2px rgba(90, 90, 90, 0.12);
    }

    &-image {
      height: 80px;
      border-top-left-radius: 0.75vh;
      border-top-right-radius: 0.75vh;
      margin: 0 auto;
      border-bottom: 2px solid lightgrey;
    }

    &-title {
      padding-left: 2vh;
      padding-right: 2vh;

      .ellipsis {
        font-size: 1.2em;
      }

      .selected {
        font-size: 1.2em;
      }
    }
  }
}

.auction-categories-content {
  //overflow-y: auto;
  position: relative;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 10;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  padding: 4px;
}
