.history-page {
  background: $background;
}

.my-history{
  &-header{
    display: flex;
    align-items: center;
    width: 100%;
    color: #646464;
    font-weight: 500;
    position: relative;

    &-wrapper{
      padding: 15px 12px;
    }

    .sortable-header{
      .q-icon{
        transition: opacity .3s ease-in-out;
        margin-left: 5px;
        font-size: 16px;
      }
      &:not(.active) {
        .q-icon{
          opacity: .3;
        }
        &:hover{
          .q-icon{
            opacity: .8;
          }
        }
      }
    }

    .my-history-card{
      &-main {
        padding-left: 76px;

        @media (max-width: $breakpoint-sm-max) {
          display: none;
        }
      }

      &-category-type{
        @media (max-width: $breakpoint-sm-max) {
          display: none;
        }
      }

      &-strain-type{
        @media (max-width: $breakpoint-sm-max) {
          display: none;
        }
      }

      &-price{
        @media (max-width: $breakpoint-sm-max) {
          display: none;
        }
      }

      &-fee{
        @media (max-width: $breakpoint-sm-max) {
          display: none;
        }
      }

      &-filters{
        width: 40%;
        text-align: right;

        @media (max-width: $breakpoint-sm-max) {
          width: 100%;
        }

        @media (min-width: $breakpoint-md-min) {
          width: auto;
          position: absolute;
          right: 0;
          bottom: 0;
        }

        > *{
          margin-right: -12px;
          margin-left: 22px;
        }
      }
    }

    &-select{
      background: #fff;
      display: inline-flex;
      border-radius: 6px;
      overflow: hidden;
      min-width: 110px;

      .q-field{
        &__native{
          color: $dark;
          font-weight: 500;
        }
        &__label{
          color: #565656;
        }
      }

      .q-select{
        &__dropdown-icon{
          color: $dark;
          font-size: 14px;
        }
      }
    }

  }

  &-scroll{
    max-height: 100vh;
  }

  &-card {
    color: $dark;
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    @media (max-width: $breakpoint-sm-max) {
      flex-wrap: wrap;
    }

    &-list-wrapper{
      padding: 0;
    }

    &-wrapper{
      border-radius: 8px;
      padding: 10px 12px;
      margin-bottom: 10px;
      min-height: 80px;
      width: 100%;
      display: block;
      text-decoration: none!important;

      &:not(.q-skeleton){
        background: #fff;
      }
    }

    &-main {
      width: 25%;
      display: inline-flex;
      align-items: center;

      @media (max-width: $breakpoint-sm-max) {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    &-category-type{
      width: 15%;

      @media (max-width: $breakpoint-sm-max) {
        width: 25%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 50%;
      }
    }

    &-strain-type{
      width: 15%;

      @media (max-width: $breakpoint-sm-max) {
        width: 25%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 50%;
      }
    }

    &-price{
      width: 15%;

      @media (max-width: $breakpoint-sm-max) {
        width: 25%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 50%;
      }
    }

    &-fee{
      width: 15%;

      @media (max-width: $breakpoint-sm-max) {
        width: 25%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 50%;
      }
    }

    &-date{
      width: 15%;
      //text-align: right;

      @media (max-width: $breakpoint-sm-max) {
        width: 50%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 50%;
      }
    }

    &-image {
      background: $dark;
      border-radius: 8px;
      margin-right: 16px;
      min-width: 60px;
      min-height: 60px;
    }

    &-clickable{
      color: $primary;
      font-weight: 500;

      &.danger{
        color: $negative;
      }
    }

    &-title{
      font-size: 16px;
      font-weight: 600;
    }

    &-qty{
      font-size: 14px;
      font-weight: 600;
    }

    &-lot{
      font-size: 14px;
      span{
        font-weight: 600;
      }
    }
  }
}
