.bulk-inventory-edit-page {
  background: $background;

  .selected-items-list {
    max-height: 300px;
    overflow-y: auto;
    padding: 20px 0 20px 0;

    .q-list {
      .q-item {
        display: flex;
        align-items: center;
        padding: 0;
        border-bottom: 1px solid #eee;

        &:last-child {
          border-bottom: none;
        }

        .bulk-inventory-items-card {
          display: flex;
          width: 100%;
          align-items: center;
          padding: 5px 8px;
          background-color: #fff;

          &-wrapper {
            border-radius: 8px;
            padding: 10px 12px;
            margin-bottom: 10px;
            min-height: 80px;
            flex-wrap: wrap;
          }

          &-select {
            margin-right: 10px;
          }

          &-image {
            width: 60px;
            height: 60px;
            flex-shrink: 0;
            background-color: #f3f3f3;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;

            img,
            .no-image-text {
              width: 100%;
              height: 100%;
            }
          }

          &-title {
            flex-grow: 1;
            font-size: 16px;
            font-weight: 600;
          }

          &-category-type {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .bulk-inventory-edit-page
    .selected-items-list
    .q-item
    .bulk-inventory-items-card {
    flex-direction: column;
    align-items: start;

    &-image,
    &-title,
    &-category-type,
    &-strain-type {
      margin-bottom: 10px;
    }

    &-select {
      order: -1;
    }
  }
}
