.company-page {
  background: $background;
}

.my-company-facilities {
  &-card {
    color: $dark;
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    @media (max-width: $breakpoint-sm-max) {
      flex-wrap: wrap;
    }

    &-list-wrapper {
      padding: 0;
    }

    &-wrapper {
      border-radius: 8px;
      padding: 10px 0;
      margin-bottom: 10px;
      min-height: 45px;
      width: 100%;
      display: block;
      text-decoration: none !important;

      &:not(.q-skeleton) {
        background: #fff;
      }
    }

    &-main {
      width: 30%;

      @media (max-width: $breakpoint-sm-max) {
        width: 50%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 50%;
      }
    }

    &-license {
      width: 30%;
      &-number {
        font-weight: 600;
      }

      @media (max-width: $breakpoint-sm-max) {
        width: 50%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 100%;
      }
    }

    &-permissions {
      width: 20%;

      @media (max-width: $breakpoint-sm-max) {
        width: 50%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 100%;
      }

      .last-polled-period {
        margin-top: 6px;
        font-size: 12px;

        .period {
          font-weight: 600;
        }
      }
    }

    &-actions {
      width: 20%;
      text-align: right;

      @media (max-width: $breakpoint-sm-max) {
        width: 50%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 100%;
      }
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
