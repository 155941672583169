.package-transfer-page,
.package-wholesale-page,
.new-package-transfer-page {
  background: $background;
}

.package-items-card-main {
  display: flex;
  flex-direction: row;
  width: var(--header-main-width);
  align-items: center;
}

.package-items-card-batch {
  width: var(--header-tag-width);
}

.package-items-card-harvest {
  width: var(--header-tag-width);
}

.package-items-card-main-tag {
  display: flex;
  flex-direction: row;
  width: var(--header-tag-width);
  align-items: center;
}

.package-item-details {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.package-items {
  max-height: calc(100vh - var(--header-height));
  overflow-y: auto;

  &-header {
    display: flex;
    align-items: center;
    width: 100%;
    color: #646464;
    font-weight: 500;
    position: relative;

    &-wrapper {
      padding: 15px 0px;
      margin-right: 20px;
    }

    .sortable-header {
      .q-icon {
        transition: opacity 0.3s ease-in-out;
        margin-left: 5px;
        font-size: 16px;
      }
    }

    .package-items-card {
      &-main {
        // padding-left: var(--header-padding-left);

        @media (max-width: $breakpoint-sm-max) {
        }
      }
      &-title {
        @media (max-width: $breakpoint-sm-max) {
        }
      }
      &-quantity {
        @media (max-width: $breakpoint-sm-max) {
        }
      }
      &-tag {
        @media (max-width: $breakpoint-sm-max) {
        }
      }
    }
  }

  &-select {
    background: #fff;
    display: inline-flex;
    border-radius: 6px;
    overflow: hidden;
    min-width: 110px;

    .q-field {
      &__native {
        color: $dark;
        font-weight: 500;
      }
      &__label {
        color: #565656;
      }
    }

    .q-select {
      &__dropdown-icon {
        color: $dark;
        font-size: 14px;
      }
    }
  }

  &-scroll {
    max-height: 60vh;
  }

  &-card {
    color: $dark;
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    @media (max-width: $breakpoint-sm-max) {
      flex-wrap: wrap;
    }

    &-wrapper {
      border-radius: 8px;
      padding: 2px 10px;
      margin-bottom: 10px;
      margin-right: 20px;
      flex-wrap: wrap;
      background: #fff;
    }

    &-selection {
      width: var(--header-selection);

      flex: 0 0 auto;
      // margin-right: 16px;

      @media (max-width: $breakpoint-sm-max) {
      }
    }

    &-main {
      width: var(--header-main-width);
      display: flex;
      align-items: center;

      @media (max-width: $breakpoint-sm-max) {
      }
    }

    &-main-tag {
      width: var(--header-tag-width);
      display: flex;
      align-items: center;
      @media (max-width: $breakpoint-sm-max) {
      }
    }

    &-header-title {
      font-size: 16px;
      font-weight: 600;
      width: var(--header-title);

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }
    }

    &-header-batch {
      font-size: 16px;
      font-weight: 600;
      width: var(--header-title);

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }
    }

    &-header-harvest {
      font-size: 16px;
      font-weight: 600;
      width: var(--header-title);

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
      width: var(--header-name-width);

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }
    }

    &-batch {
      width: var(--header-batch-width);

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 66.6%;
      }
    }

    &-harvest {
      width: var(--header-harvest-width);

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 66.6%;
      }
    }

    &-quantity {
      width: var(--header-quantity-width);

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 66.6%;
      }
    }

    &-price {
      width: var(--header-quantity-width);

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 66.6%;
      }
    }

    &-price-input {
      width: var(--header-new-quantity-width);
      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }
      @media (max-width: $breakpoint-xs-max) {
        width: 66.6%;
      }
    }

    &-tag {
      width: 20%;
      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }
      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }
    }
    &-quantity-title {
      // width: 10%;
      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }
      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }
    }
    &-new-package-tag {
      width: var(--header-new-tag-width);
      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }
      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }
      &-input {
        // width: 100px;
      }
    }
    &-new-tag {
      width: var(--header-tag-width);
      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }
      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }
    }

    &-new-quantity-input {
      width: var(--header-new-quantity-width);
    }

    &-adjust-btn {
      width: 20%;
      align-items: right;
      // width: var(--header-tag-width);
    }

    &-tag {
      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }
    }

    &-filters {
      width: 10%;
      text-align: right;
      @media (min-width: $breakpoint-md-min) {
        width: auto;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
}

.new-package-items-card-main-header {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  width: 20%;
  align-items: center;

  @media (max-width: $breakpoint-sm-max) {
    width: 20%;
  }
}

.new-package-item-details {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.download-container {
  display: flex;
  justify-content: space-between;
  align-items: right;
  margin-top: 20px;
}

.new-package-items {
  &-header {
    display: flex;
    align-items: center;
    width: 100%;
    color: #646464;
    font-weight: 500;
    position: relative;

    &-wrapper {
      padding: 15px 0px;
    }

    &-main {
      width: 20%;
      padding-left: 20px;

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }
      @media (max-width: $breakpoint-xs-max) {
        width: 20%;
      }
    }

    &-harvest-name {
      width: 20%;
      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }
    }

    &-quantity {
      width: 20%;
      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }
    }

    &-item-name {
      width: 20%;
      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }
    }

    &-tag {
      width: 20%;

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }
    }
  }

  &-card {
    color: $dark;
    font-size: 14px;
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-sm-max) {
      flex-wrap: wrap;
    }

    &-wrapper {
      border-radius: 8px;
      padding: 10px 12px;
      margin-bottom: 10px;
      min-height: 80px;
      flex-wrap: wrap;
      background: #fff;
    }

    &-main {
      padding-left: 20px;
      width: 20%;

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }
    }

    &-harvest-name {
      width: 20%;
      font-weight: 500;

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }
    }

    &-item-name {
      width: 20%;

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }
    }

    &-quantity {
      width: 20%;

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 66.6%;
      }
    }

    &-tag {
      width: 20%;

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }
    }
  }
}
