.buyer-request-product-to-offer{
  &-list{
    &-item{
      padding: 6px 8px;
      &:nth-child(even) {
        background: $grey-1;
      }
    }
  }
  &-checkbox{
    .q-checkbox{
      &__label{
        flex-grow: 1;
        .product-title{
          font-size: 14px;
          font-weight: 600;
        }
        .product-available{
          font-size: 14px;
        }
      }
    }
  }
  &-inventory{
    border: 1px solid $grey-4;
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding: 6px 10px;
    background: #fff;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }
}
