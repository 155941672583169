.gl-list{
  &-wrapper{

  }
  &-item{
    padding: 8px 0;
    //min-height: unset;
    border-bottom: 1px solid #c5cad1;
    &:last-child{
      border-bottom: none;
    }

    &-text{
      display: block;
      font-weight: 500;
      &.error{
        color: $negative;
      }
    }
    &-title{
      display: block;
      font-weight: 600;
    }
    &-description{
      display: block;
      color: #48505c;
      font-size: 14px;
      font-style: italic;
    }

    .q-item__section--avatar{
      min-width: unset;
    }
  }

}

.gl-suggestion-popup{
  left: 0;
  right: 0;
  top: 84px;
  position: absolute;

  &-backdrop{
    background: rgba(12, 60, 7, 0.5) !important;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    height: 100%;
    width: 100%;
    position: absolute;
  }

  &-body{
    position: relative;
    z-index: 99;
    max-width: 400px;
    background: $secondary;
    border: 5px solid $accent;
    //border: 5px solid $green;
    padding: 15px;
    margin: 15px auto;
    border-radius: 4px;
    box-shadow: 0 0 0 0 rgba(95, 170, 67, 1);
    animation: pulse-gl-suggestion 2s infinite;
  }

  &-title{
    font-size: 16px;
    margin: 0 0 10px 0;
    text-align: center;
    line-height: 1;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.or-separator{
  position: relative;
  height: 1px;
  background: linear-gradient(to right, currentColor calc(50% - 20px), transparent 0);
  margin-top: 12px;
  margin-bottom: 12px;
  &:before {
    content: 'OR';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:after{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 1px;
    width: calc(50% - 20px);
    background: currentColor;
  }
}

@keyframes pulse-gl-suggestion {
  0% {
    box-shadow: 0 0 0 0 rgba(95, 170, 67, 0.7);
    //box-shadow: 0 0 0 0 rgba(65, 193, 149, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(95, 170, 67, 0);
    //box-shadow: 0 0 0 10px rgba(65, 193, 149, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(95, 170, 67, 0);
    //box-shadow: 0 0 0 0 rgba(65, 193, 149, 0);
  }
}

.fullscreen-modal {
  .q-dialog {
    &__inner {
      background: #fff;
      margin-left: auto;
      margin-right: auto;
      > div {
        box-shadow: none;
      }
    }
  }

  &-content {
    position: relative;
    background: #fff;
    overflow-x: hidden!important;
    &.scroll{
      overflow-y: hidden!important;
    }
  }

  &-body{
    display: flex;
    flex-direction: column;
    &-main{
      flex-grow: 1;
    }
  }

  &-header {
    &.with-shadow {
      box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
    }

    .page-header {
      margin-bottom: 0;
    }

    &.no-border {
      .page-header {
        border-bottom-color: transparent;
      }
    }
  }

  &.has-fixed-header {
    .q-dialog {
      &__inner {
        //padding-top: 78px;
      }
    }

    .fullscreen-modal-header {
      position: fixed;
      width: 100%;
      top: 0;
      z-index: 2;
      background: #fff;
    }

    .fullscreen-modal-body{
      height: 100%;
      &-main{
        overflow-y: hidden;
      }
      .fullscreen-modal-header{
        visibility: hidden;
        position: static;
      }
    }
  }
}
