@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
:root {
  --q-background: #{$background};
}

body {
  user-select: none;
  overflow: hidden;
}

.container {
  padding: 0 16px;
}

.add-listing-form .q-field__control {
  font-size: 16px;
}

footer {
  z-index: 3000;

  .q-tabs {
    overflow: hidden;
    box-shadow: -1vh 0 12vh rgba(0, 0, 0, 0.37);
    border-radius: 4vw 4vw 0 0;
  }
}

aside,
header {
  background: rgba(255, 255, 255, 1) !important;
}
aside.q-drawer--mobile {
  background: rgba(238, 255, 248, 0.87) !important;
  backdrop-filter: blur(12px) brightness(150%);

  .q-drawer__content {
    box-shadow: inset 0 0 12vh adjust-color($dark, $alpha: -0.5);
  }
}

iframe {
  border: 0px;
}

.bg-light {
  background: var(--q-background) !important;
}

.q-dialog__backdrop {
  background: rgba(12, 60, 7, 0.5) !important;
  backdrop-filter: blur(12px);
}

.q-scrollarea {
  &:not(.horizontal-scrollbar) {
    > .q-scrollarea__container {
      > .q-scrollarea__content {
        width: 100% !important;
      }
    }
  }
  &:not(.vertical-scrollbar) {
    > .q-scrollarea__container {
      > .q-scrollarea__content {
        height: 100% !important;
      }
    }
  }
  &:not(.horizontal-scrollbar),
  &:not(.vertical-scrollbar) {
    > .q-scrollarea__thumb {
      background: $primary;
      opacity: 0.5;
      &:hover,
      &:active {
        opacity: 1;
      }
    }
    &:hover {
      > .q-scrollarea__thumb {
        opacity: 0.5;
      }
    }
  }
}
body.touch {
  .q-scrollarea {
    &__bar,
    &__thumb {
      display: none !important;
    }
  }
}
//.q-scrollarea__content {
//  width: 100% !important;
//  height: 100% !important;
//}

.q-avatar .q-icon {
  width: inherit;
  height: inherit;
}

.fade-top {
  height: 60px;
  background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  pointer-events: none;
}

.fade-bottom {
  height: 60px;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  pointer-events: none;
}

.fade-left {
  width: 50px;
  background: linear-gradient(
    -90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 1)
  );
  pointer-events: none;
}

.fade-right {
  width: 40px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 1)
  );
  pointer-events: none;
}

.fade-left-short {
  width: 20px;
  background: linear-gradient(
    -90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 2555, 1)
  );
  pointer-events: none;
}

.fade-right-diagonal {
  width: 90px;
  background: radial-gradient(
    farthest-side at 100% 5%,
    rgba(255, 255, 2555, 1),
    rgba(255, 255, 255, 0)
  );
  pointer-events: none;
}

.avatar-cropper,
.image-cropper {
  background: #fff;
  position: relative;
  &.with-image {
    @media screen and (min-width: $breakpoint-sm-min) {
      min-width: 550px;
    }
  }
  .cropper-view-box {
    border-radius: 50%;
  }
  &-holder {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    @media screen and (min-width: $breakpoint-sm-min) {
      max-height: 60vh;
      max-width: 60vh;
      overflow: hidden;
    }
  }
  &-spinner {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    .q-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &-actions {
    display: flex;
    justify-content: space-between;
    .q-btn {
      flex-grow: 1;
      max-width: 80px;
      @media screen and (max-width: $breakpoint-sm-min) {
        max-width: 55px;
      }
    }
  }
}
.image-cropper {
  .cropper-view-box {
    border-radius: 0;
  }
}

.page-separator {
  height: 1px;
  background: $grey-5;
}

.form-block {
  margin-top: 32px;
  margin-bottom: 32px;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    &-left {
      display: inline-flex;
      align-items: baseline;
    }

    &-title {
      font-size: 24px;
      line-height: 24px;
      color: $dark;
      font-weight: 700;
      &.error {
        color: $negative;
      }

      .optional-label {
        font-size: 16px;
        line-height: 16px;
        //vertical-align: text-bottom;
        color: $grey-8;
      }
    }

    &-tip {
      margin-left: 8px;
      cursor: pointer;
    }
  }

  &-body {
    .field-about {
      font-size: 14px;
      line-height: 21px;
      color: $grey-8;
      font-weight: 400;
      margin-bottom: 24px;

      &.small-mb {
        margin-bottom: 12px;
      }
    }

    .field-label {
      font-size: 12px;
      line-height: 12px;
      font-weight: 700;
      color: $grey-8;
      margin-bottom: 12px;
      display: block;
    }

    .button-select {
      display: flex;

      .q-btn {
        padding-top: 16px;
        padding-bottom: 16px;
        line-height: 1;
        font-size: 16px;
        flex-grow: 1;
      }
    }
  }
}

.tier-star-level {
  display: inline-flex;
  gap: 4px;
}
.tier-star {
  stroke-width: 20px;
  &-wrap {
    fill: transparent;
    stroke: $primary;
  }
  &-left,
  &-right {
    fill: #fff;
    &.filled {
      fill: $primary;
    }
  }
  &.editable {
    .tier-star {
      &-left,
      &-right {
        cursor: pointer;
      }
    }
  }
}

.q-field {
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.chat-input-note {
  color: #48505c;
  font-size: 13px;
  font-style: italic;
}

.monetary-field {
  max-width: 300px;
  text-align: left;
  margin-left: auto;
  margin-right: 10px;
}

.new-transfer-card {
  background: #fff;
  color: #2a6b0e;
  text-align: center;
  justify-content: center;
  font-size: 40px !important;
  font-weight: bold;
  height: 86px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.no-img-row {
  padding-left: 12px !important;
}

.no-decoration {
  text-decoration: none;
}

.sortable-header {
  .q-icon {
    transition: opacity 0.3s ease-in-out;
    margin-left: 5px;
    font-size: 16px;
  }
  &:not(.active) {
    .q-icon {
      opacity: 0;
    }
    &:hover {
      .q-icon {
        opacity: 0.8;
      }
    }
  }
}

.checkout-modal .modal-page-listing-card {
  background-color: #f8f9f8;
}

#panel-filters {
  .q-slide-item {
    &__content {
      cursor: default;
    }
  }
}

.header-title-cont {
  align-items: center;
  align-self: center;
  margin-right: 40px;
  margin-left: 40px;
  height: 62px;
  span {
    font-size: 32px;
    font-weight: 700;
    line-height: 62px;
  }
}
.header-inv-summary {
  height: 62px;
  margin-left: 40px;
}

@import "global-elements";
@import "auth";
@import "listings";
@import "auctions";
@import "cart";
@import "inventory";
@import "history";
@import "company";
@import "buyer-requests";
@import "categories";
@import "batch-transfer";
@import "package-transfer";
@import "selected-item";
@import "orders";
@import "bulk-inventory-edit";
@import "leaf-link-import";
@import "transfers";
