.auth-page{
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 480px) {
    padding: 15px;
  }
}
.auth-form{
  width: 100%;
  max-width: 380px;
}
.auth-button{
  margin-bottom: 12px;
  border-radius: 12px;
  height: 48px;
  &.small{
    height: 32px;
  }
  &.no-margin{
    margin-bottom: 0;
  }
}
.auth-field{
  margin-top: 0!important;
  margin-bottom: 12px!important;
  .form-field{
    &-header{
      margin-bottom: 12px;
    }
    &-title{
      color: #fff;
      font-weight: 700;
      font-size: 12px;
      line-height: 100%;
    }
  }
  .q-field{
    &__control{
      height: 48px;
      border-radius: 12px;
    }
    &--error{
      .q-field__bottom{
        .q-field__messages{
          color: #fff;
        }
      }
    }
  }
}

.auth-application-form{
  position: relative;
  background: #fff;
  width: 100%;
  max-width: 600px;
  flex-grow: 1;
}
